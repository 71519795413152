import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { Modal } from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from 'react-share'
import Swal from 'sweetalert2'
import Group from '../../../Components/FormsElements/Group'
import Input from '../../../Components/FormsElements/Input'
import styles from './styles.module.scss'
const ReferalModal = (props) => {
  return (
    <>
      <Modal
        show={props.show}
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`${styles.referal_modalmain}`}
      >
        <Modal.Body className={`${styles.referal_modalbody}`}>
          <div className={`${styles.modal_close}`} onClick={props.onHide}>
            <SVG src="/assets/social/close.svg" />
          </div>
          <div className={`${styles.referal_modalcontent}`}>
            <div className={`${styles.referal_main_box}`}>
              <div className={`mr-2`}>
                <div className={`${styles.referal_separates}`}>
                  <h2 className={`${styles.referal_heading1}`}>Refer a</h2>
                  <h3 className={`${styles.referal_heading2}`}>Friend</h3>
                  <p className={`${styles.referal_description}`}>
                    Share Your Link with your friends and you both will get
                    <span className={`${styles.referal_primary}`}>
                      200 Gems
                    </span>
                  </p>
                </div>

                <div className={`${styles.link_separates}`}>
                  <Group className={`${styles.input_sections}`}>
                    <Input
                      label={'Website URL'}
                      type={'text'}
                      placeHolder={`Website URL`}
                      // value="https://nfton.market/werfdtgy"
                      value={props?.refLink}
                      className={`${styles.bg_input}`}
                      // value={editProfileFormik.values.website_url}
                      // onChange={(e) => {
                      //   editProfileFormik.setValues((v) => {
                      //     return { ...v, website_url: e.target.value };
                      //   });
                      // }}
                      id="website_url"
                      // onBlur={editProfileFormik.handleBlur}
                      // touched={editProfileFormik.touched.website_url}
                      // error={editProfileFormik.errors.website_url}
                    />
                  </Group>

                  <div className={`${styles.social_separates}`}>
                    <Link href="javascript:void(0)">
                      <a
                        onClick={async () => {
                          if (navigator.clipboard && window.isSecureContext) {
                            // navigator clipboard api method'
                            navigator.clipboard.writeText(props?.refLink)
                            Swal.fire({
                              icon: 'success',
                              title:
                                'Link copied to clipboard! Paste it on social media.',
                              showConfirmButton: false,
                              timer: 2500,
                            })
                          } else {
                            // text area method
                            let textArea = document.createElement('textarea')
                            textArea.value = props?.refLink
                            // make the textarea out of viewport
                            textArea.style.position = 'fixed'
                            textArea.style.left = '-999999px'
                            textArea.style.top = '-999999px'
                            document.body.appendChild(textArea)
                            textArea.focus()
                            textArea.select()
                            await new Promise((res, rej) => {
                              // here the magic happens
                              document.execCommand('copy') ? res() : rej()
                              textArea.remove()
                            })
                            Swal.fire({
                              icon: 'success',
                              title:
                                'Link copied to clipboard! Paste it on social media.',
                              showConfirmButton: false,
                              timer: 2500,
                            })
                          }
                        }}
                        className={`${styles.link_style}`}
                      >
                        <SVG src="/assets/social/copy.svg" />
                      </a>
                    </Link>

                    <Link href="javascript:void(0)">
                      <a className={`${styles.link_style}`}>
                        {/* <SVG src="/assets/social/fb.svg" /> */}
                        <FacebookShareButton
                          quote={`Look What I found!`}
                          url={props?.refLink}
                          hashtag={'#ethereum'}
                        >
                          <SVG
                            className={`${styles.link_style}`}
                            src="/assets/social/fb.svg"
                          />
                        </FacebookShareButton>
                      </a>
                    </Link>

                    <Link href="javascript:void(0)">
                      <a className={`${styles.link_style}`}>
                        <TelegramShareButton
                          url={props?.refLink}
                          title={`Look What I found!`}
                          via="NFTON"
                        >
                          <SVG
                            className={`${styles.link_style}`}
                            src="/assets/social/telegram.svg"
                          />
                        </TelegramShareButton>
                      </a>
                    </Link>

                    <Link href="javascript:void(0)">
                      <a className={`${styles.link_style}`}>
                        <TwitterShareButton
                          url={props?.refLink}
                          title={`Look What I found!`}
                          via="NFTON"
                          hashtags={[
                            'ethereum',
                            'nonfungible',
                            'digitalasset',
                            'nft',
                          ]}
                        >
                          <SVG
                            className={`${styles.link_style}`}
                            src="/assets/social/twitter.svg"
                          />
                        </TwitterShareButton>
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
              <div className={`${styles.referal_box}`}>
                <div className={`${styles.referal_img}`}>
                  <Image
                    src="/assets/gemstarlogo.svg"
                    alt="Logo"
                    layout="fill"
                  />
                </div>

                <div className={`${styles.referal_details}`}>
                  <p className={`${styles.referal_details}`}>
                    Invite your Friends &amp;
                  </p>
                  <div className={`${styles.referal_title_separates}`}>
                    <h2 className={`${styles.referal_titles}`}>
                      Earn Gems -
                      <span className={`${styles.referal_values}`}>200</span>
                    </h2>
                    <div className={`${styles.referal_small_img}`}>
                      <Image
                        src="/assets/gemssimple.svg"
                        alt="Logo"
                        layout="fill"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={`${styles.referal_modalfooter}`}>
          <p className={`${styles.referal_details}`}>
          When your friends sign up using the referral link, both you and your friend will receive 200
            <div className={`${styles.referal_gems_small_img}`}>
              <Image src="/assets/gemssimple.svg" alt="Logo" layout="fill" />
            </div>{' '}
            each
          </p>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ReferalModal
